import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAppStateStore from 'Store/AppState.store';

import { ENDPOINTS } from 'Helpers/constants';
import usePreloadFetch from 'Hooks/usePreloadFetch';
import useTheme from 'Hooks/useTheme';

import Main from 'Layout/Main/Main';
import Sidebar from 'Layout/Sidebar/Sidebar';
import Loading from './common/Loading/Loading';
import Toast from './common/Toast/Toast';

import './App.scss';

const App = () => {
  const { t } = useTranslation();
  const { themeLoading } = useTheme();
  const { handleInitFilters } = useAppStateStore.getState();
  // eslint-disable-next-line no-underscore-dangle
  const hasHydrated = useAppStateStore((state) => state._hasHydrated);

  usePreloadFetch([
    ENDPOINTS.MODULES,
    ENDPOINTS.HIERARCHY_TYPES,
    ENDPOINTS.HIERARCHY_VALUES,
  ]);

  useEffect(() => {
    handleInitFilters();
  });

  return (
    <div className="App">
      {themeLoading && !hasHydrated ? (
        <Loading size="sm" title={t('loading_scheduler')} />
      ) : (
        <>
          <div className="App__content">
            <Sidebar />
            <Main />
          </div>
          <Toast />
        </>
      )}
    </div>
  );
};

export default App;
