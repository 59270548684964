/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { BryntumCalendar } from '@bryntum/calendar-react';
import { Widget, EventList } from '@bryntum/calendar';
import { useTranslation } from 'react-i18next';

import ErrorBoundary from 'Components/shared/ErrorBoundary';
import useAppStateStore from 'Store/AppState.store';
import useColumnStateStore from 'Store/ColumnState.store';
import { LIST_VIEW_MODES } from 'Helpers/constants';
import Stats from '../Stats/Stats';

import '@bryntum/calendar/calendar.stockholm.css';
import './Schedules.scss';
import useSchedulerData from './useSchedulerData';
import useModesConfig from './useModesConfig';
import AboutComponent from '../About/AboutComponent';
import SettingsOverlay from '../Settings/SettingsOverlay';
import ModeSelector from '../ModeSelector/ModeSelector';

const Schedules = () => {
  const { t } = useTranslation();
  const { handleSetCalendarMode } = useAppStateStore.getState();
  const { handleSetColumnState } = useColumnStateStore.getState();

  const columnStateRef = useRef(useColumnStateStore.getState().leadColumns);

  const filterStatesRef = useRef(useAppStateStore.getState().filters);
  const calendarStatesRef = useRef(useAppStateStore.getState().calendar);
  const calendarModeRef = useRef(useAppStateStore.getState().mode);
  const hierarchyTypes = useRef(useAppStateStore.getState().hierarchyTypes);
  const filterFormIdsRef = useRef(useAppStateStore.getState().filterFormIds);
  const hierarchyIdsRef = useRef(useAppStateStore.getState().hierarchyIds);
  const currentPageNumberRef = useRef(
    useAppStateStore.getState().currentPageNumber
  );

  const calendarRef = useRef<BryntumCalendar>(null);

  const { dateStart, dateEnd } = calendarStatesRef.current;
  const dateStartRef = useRef(dateStart);
  const dateEndRef = useRef(dateEnd);

  const {
    store,
    data: schedulerData,
    isLoading,
  } = useSchedulerData({
    formIds: filterFormIdsRef.current,
    hierarchyIds: hierarchyIdsRef.current,
    dateStart,
    dateEnd,
    hierarchyTypes: hierarchyTypes.current,
    page: currentPageNumberRef.current,
  });

  const { modes } = useModesConfig({
    // pagination config
    page: currentPageNumberRef.current,
    availablePages: schedulerData?.availablePages,
    totalSize: schedulerData?.totalSize,
    // columns config
    leadColumns: columnStateRef.current,
    hierarchyTypes: hierarchyTypes.current,
    calendar: calendarStatesRef.current,
  });

  const handleCacheColumns = useCallback(
    (data: Record<string, unknown> | Record<string, unknown>[]) => {
      let columns = data;

      if (!Array.isArray(data)) {
        // @ts-ignore
        columns = columnStateRef.current as [];
        const currentColumn =
          columns.find(
            (column: Record<string, unknown>) => column.id === data.id
          ) || {};

        if (currentColumn) {
          currentColumn.hidden = data.hidden;
          currentColumn.region = data.hidden ? 'normal' : data.region;
        }
      } else {
        columns = (columns as []).filter(
          (column: Record<string, unknown>) => column.field !== 'date'
        );
      }

      handleSetColumnState(columns as Record<string, unknown>[]);
    },
    [handleSetColumnState]
  );

  // 1. Connect to the store on mount, disconnect on unmount,
  // catch state-changes in a reference
  useEffect(() => {
    useAppStateStore.subscribe(
      // eslint-disable-next-line no-return-assign
      (state) => (
        // eslint-disable-next-line no-sequences
        (filterStatesRef.current = state.filters),
        (calendarStatesRef.current = state.calendar),
        (calendarModeRef.current = state.mode),
        (hierarchyTypes.current = state.hierarchyTypes),
        (filterFormIdsRef.current = state.filterFormIds),
        (hierarchyIdsRef.current = state.hierarchyIds),
        (currentPageNumberRef.current = state.currentPageNumber)
      )
    );
    useColumnStateStore.subscribe(
      // eslint-disable-next-line no-return-assign
      (state) => (columnStateRef.current = state.leadColumns)
    );
  }, []);

  // 2 and 3 performed by the 'useSchedulerData' hook

  // 4. Manually Set Store Data to Events
  // Listen to Column Store 'change' Event to update cached Columns
  useEffect(() => {
    if (!isLoading) {
      dateStartRef.current = dateStart;
      dateEndRef.current = dateEnd;

      if (calendarRef.current?.instance && store?.data) {
        // load new data in the event store
        calendarRef.current.instance.eventStore.data = store.data;

        LIST_VIEW_MODES.forEach((mode) => {
          const calendar = calendarRef.current?.instance.modes[mode];

          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          calendar?._columnStore.on('catchAll', (event) => {
            if (event?.action === 'batch') {
              handleCacheColumns(event.data);
            } else if (event?.action === 'update') {
              handleCacheColumns(event.records[0].data);
            }
          });
        });
      }
    }
  }, [handleCacheColumns, isLoading, store?.data, dateStart, dateEnd]);

  const modeDefaults = useMemo(() => {
    return {
      range: 'decade',
      readOnly: true,
      syncViewDate: false,
      emptyText: store?.data.length ? t('preparing_list') : t('no_records'),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.data]);

  const getCalendarRef = useCallback(() => {
    // we use this in the toolbar to ensure custom sub-widgets have access to the calendar ref
    return calendarRef.current;
  }, []);

  const toolbarConfig = useMemo(() => {
    return {
      cls: 'Schedules__toolbar',
      itemCls: 'Schedules__toolbar__item',
      items: {
        // hide built-in toolbar items
        todayButton: null,
        prevButton: null,
        nextButton: null,
        viewDescription: null,
        modeSelector: null,
        // show custom toolbar items
        stats: {
          type: 'widget',
          weight: 1,
          html: <Stats />,
        },
        customModeSelector: {
          type: 'widget',
          weight: 2,
          html: <ModeSelector calendar={getCalendarRef} />,
        },
        settings: {
          type: 'widget',
          weight: 3,
          html: <SettingsOverlay calendar={getCalendarRef} />,
        },
        about: {
          type: 'widget',
          weight: 4,
          html: <AboutComponent />,
        },
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActiveItemChange = useCallback(
    ({ activeItem }: { activeItem: Widget }) => {
      handleSetCalendarMode((activeItem as EventList).modeName);
    },
    [handleSetCalendarMode]
  );

  if (filterFormIdsRef.current === '') {
    return (
      <div className="odin-flex odin-h-full odin-w-full odin-items-center odin-justify-center">
        {t('form_selected_required')}
      </div>
    );
  }

  return (
    <div className="Schedules odin-h-full">
      <ErrorBoundary>
        <BryntumCalendar
          ref={calendarRef}
          masked={isLoading ? t('getting_schedules') : false}
          date={new Date(dateStart as string)}
          readOnly
          sidebar={false}
          eventEditFeature={false}
          eventTooltipFeature={false}
          onActiveItemChange={handleActiveItemChange}
          mode={calendarModeRef.current}
          modeDefaults={modeDefaults}
          modes={modes}
          tbar={toolbarConfig}
        />
      </ErrorBoundary>
    </div>
  );
};

export default Schedules;
