import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from '@myosh/myosh-login';

const PrivateRoute = () => {
  const { state: authState } = useUser();
  const isAuthenticated = authState.auth;
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
