import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthService, useUser } from '@myosh/myosh-login';

import useAppStateStore from 'Store/AppState.store';
import useColumnStateStore from 'Store/ColumnState.store';

const authService = new AuthService();
interface Props {
  children: JSX.Element;
}

const UsernameGuard = ({ children }: Props) => {
  const { search } = useLocation();
  const { state } = useUser();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (state) {
      const currentUsername = state.user?.prefered_username;

      if (searchParams) {
        const incomingUsername =
          searchParams.get('userName') ||
          searchParams.get('username') ||
          undefined;

        if (
          incomingUsername &&
          currentUsername &&
          incomingUsername.toLowerCase() !== currentUsername.toLowerCase()
        ) {
          localStorage.clear();
          authService.logout();
        }
      }

      if (currentUsername) {
        useAppStateStore.persist.setOptions({
          name: `app-state_${currentUsername}`,
        });
        useAppStateStore.persist.rehydrate();

        useColumnStateStore.persist.setOptions({
          name: `column-state_${currentUsername}`,
        });
        useColumnStateStore.persist.rehydrate();

        localStorage.removeItem('app-state');
        localStorage.removeItem('column-state');
      }
    }
  }, [searchParams, state]);

  return children;
};

export default UsernameGuard;
