import { Route, Routes, Navigate } from 'react-router-dom';
import { LoginCallback, LogoutCallback } from '@myosh/myosh-login';
import { SWRConfig } from 'swr';
import App from 'Components/App';
import localStorageProvider from 'Helpers/cache-provider';
import PrivateRoute from './private-routes.component';
import LoginPage from './login-page';

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/auth/cb/logout" element={<LogoutCallback />} />
      <Route path="/auth/cb/silent" />
      <Route path="/auth/cb" element={<LoginCallback />} />
      <Route path="/login" element={<LoginPage />} />

      <Route path="/" element={<PrivateRoute />}>
        <Route
          index
          element={
            <SWRConfig
              value={{
                provider: localStorageProvider,
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
              }}
            >
              <App />
            </SWRConfig>
          }
        />
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default RoutesComponent;
