declare const VERSION: string;

export const APP_VERSION = VERSION;

export const API_CONFIG = {
  KEY: process.env.REACT_APP_API_KEY,
  URL: process.env.REACT_APP_API_V4,
};

export const VIKING_DEFAULT_URL = process.env.REACT_APP_VIKING_DEFAULT_URL;

export const ENDPOINTS = {
  THEME: 'theme',
  MODULES:
    'modules/simple?includeFormList=true&filter=enableCalendarView:eq:true',
  HIERARCHY_TYPES: 'hierarchy-types/simple',
  HIERARCHY_VALUES: 'hierarchy-values/simple',
  RECORD_COUNT: 'records/count/details',
  SCHEDULES: 'schedules/reports',
  SCHEDULES_COUNT_PROJECTED_RECORDS: 'schedules/count-projected-records',
  SCHEMA: 'schema',
};

export const DATE_PATTERNS: Record<string, Intl.DateTimeFormatOptions> = {
  MDY: {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  },
  MY: {
    month: 'short',
    year: 'numeric',
  },
  Y: {
    year: 'numeric',
  },
};

export const COMPLETED_STATUSES = [
  'Complete',
  'Completed',
  'Resolved',
  'Archived',
  'Closed',
  'Completed (Test form)',
];

export const WEEK_VIEW = 'week';
export const MONTH_VIEW = 'month';
export const YEAR_VIEW = 'year';

export const LIST_VIEW_MODES = [WEEK_VIEW, MONTH_VIEW, YEAR_VIEW];

export const DEFAULT_LIST_MODE = MONTH_VIEW;

export const PAGE_SIZE = 25;
