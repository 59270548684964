import './Gutter.scss';

type PropType = {
  gutterRef: React.RefObject<HTMLInputElement>;
};

const Gutter = ({ gutterRef }: PropType) => {
  return <div className="Gutter" ref={gutterRef} />;
};

export default Gutter;
