import ErrorBoundary from 'Components/shared/ErrorBoundary';
import FormsFilter from 'Modules/FormsFilter/FormsFilter';
import HierarchyFilter from 'Modules/HierarchyFilter/HierarchyFilter';

import './Filters.scss';

const Filters = () => {
  return (
    <div className="Filters">
      <div className="Filters__content">
        <ErrorBoundary>
          <FormsFilter />
          <HierarchyFilter />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Filters;
