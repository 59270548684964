import useAppStateStore from 'Store/AppState.store';
import { PAGE_SIZE } from 'Helpers/constants';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

interface PaginationProps {
  page: number;
  availablePages?: number;
  totalSize?: number;
}

// Provides the BryntumCalendar bottom bar pagination configuration
const usePaginationConfig = ({
  page,
  availablePages = 0,
  totalSize = 0,
}: PaginationProps) => {
  const { t } = useTranslation();

  const { handleSetFilteringLoader, handleSetCurrentPageNumber } =
    useAppStateStore.getState();

  const handlePageButtonClick = useCallback(
    (pageNumber: number) => {
      handleSetFilteringLoader(true);
      handleSetCurrentPageNumber(pageNumber);
    },
    [handleSetCurrentPageNumber, handleSetFilteringLoader]
  );

  const paginationConfig = useCallback(() => {
    if (availablePages === 0 || totalSize === 0) {
      return false;
    }

    return {
      items: {
        firstPageButton: {
          onClick: () => handlePageButtonClick(1),
          icon: 'b-icon-first',
          disabled: page === 1,
        },
        previousPageButton: {
          onClick: () => handlePageButtonClick(page - 1),
          icon: 'b-icon-previous',
          disabled: page === 1,
        },
        pageCount: {
          type: 'widget',
          cls: 'b-pagecount b-toolbar-text',
          html: t('page', {
            current: page,
            total: availablePages,
          }),
        },
        nextPageButton: {
          onClick: () => handlePageButtonClick(page + 1),
          icon: 'b-icon-next',
          disabled: page === availablePages,
        },
        lastPageButton: {
          onClick: () => handlePageButtonClick(availablePages),
          icon: 'b-icon-last',
          disabled: page === availablePages,
        },
        spacer: {
          type: 'widget',
          cls: 'b-toolbar-fill',
        },
        reloadButton: null,
        dataSummary: {
          type: 'widget',
          cls: 'b-toolbar-text',
          html: t('displaying_records_rows', {
            start: page === 1 ? 1 : PAGE_SIZE * (page - 1) + 1,
            end:
              totalSize > 1
                ? `-${page === availablePages ? totalSize : PAGE_SIZE * page}`
                : '',
            total: totalSize,
          }),
        },
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePages, page, totalSize, handlePageButtonClick]);

  return {
    paginationConfig,
  };
};

export default usePaginationConfig;
