import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Checkbox,
  CheckboxChangeEventType,
  DropDownMultiSelect,
  DropDownResult,
  IconButton,
  OdinIcon,
  OdinIconType,
} from '@myosh/odin-components';

import useAppStateStore from 'Store/AppState.store';
import { useTranslation } from 'react-i18next';

import { findExistingIndexById } from 'Helpers/helpers';

import './CheckboxListFilter.scss';
import useFetchData from 'Hooks/useFetchData';
import { ENDPOINTS } from 'Helpers/constants';

type HierarchyValue = {
  id: number;
  hierarchyTypeId: number;
  caption: string;
  archived: boolean;
  lastChanged: string;
  external: boolean;
};

type CheckboxListFilterItemType = {
  id: number;
  caption: string;
};

type CheckboxListFilterType = {
  filterType: string;
  item: CheckboxListFilterItemType;
};

// This component is based on the CheckboxListFilter and follows most of it's conventions
const HierarchyCheckboxListFilter = ({
  filterType,
  item,
}: CheckboxListFilterType) => {
  // we try to fetch all items in a single request as we have no way to load the persisted items if they are out of range from the loaded page
  const { isLoading, data: { items } = {} } = useFetchData(
    `${ENDPOINTS.HIERARCHY_VALUES}?filter=archived:eq:false&sort=caption&filter=typeId:eq:${item.id}&pageSize=1000`
  );

  const storedSelectedFilters = useAppStateStore(
    (s) => s.tempFilters[filterType]
  );

  const { handleSetSelectedFilters, handleRemoveSelectedFilters } =
    useAppStateStore.getState();

  const { t } = useTranslation();

  const [isToggled, setIsToggled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const selectedFilterRef = useRef<(number | number[])[]>([]);
  const selectedChildFiltersRef = useRef<DropDownResult[]>();

  const transformedHierarchyValues = useMemo(() => {
    if (!isLoading && items) {
      return items.map((hierarchyValue: HierarchyValue) => {
        return { value: hierarchyValue.id, text: hierarchyValue.caption };
      });
    }
    return [];
  }, [isLoading, items]);

  useEffect(() => {
    if (!isLoading && transformedHierarchyValues && item.id) {
      const storedFilterIndex = findExistingIndexById(
        storedSelectedFilters ?? [],
        item.id
      );

      if (storedFilterIndex !== -1) {
        const storedChildFilters = storedSelectedFilters[storedFilterIndex];
        const storedChildFilterIndexes = Array.isArray(storedChildFilters)
          ? storedChildFilters[1]
          : [];

        if (storedChildFilterIndexes.length > 0) {
          selectedChildFiltersRef.current = transformedHierarchyValues.filter(
            (hierarchyValue: DropDownResult) =>
              storedChildFilterIndexes.includes(hierarchyValue.value)
          );
        }
      } else {
        selectedChildFiltersRef.current = [];
        selectedFilterRef.current = [];
      }

      setIsChecked(storedFilterIndex !== -1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedSelectedFilters]);

  const handleFilterToggle = (): void => {
    setIsToggled(!isToggled);
  };

  /**
   * Triggers when selecting child filter/s
   * @param {DropDownResult[]} childFilters
   */
  const handleSetParentFilterCheck = (childFilters: DropDownResult[]): void => {
    const hasSelectedChildFilters = childFilters.length > 0;

    if (hasSelectedChildFilters) {
      selectedFilterRef.current = [
        item.id,
        childFilters.map((selectedItem) => selectedItem.value as number),
      ];
      handleSetSelectedFilters(filterType, selectedFilterRef.current);
    } else {
      handleRemoveSelectedFilters(filterType, item.id);
      selectedFilterRef.current = [];
    }

    selectedChildFiltersRef.current = [...childFilters];

    setIsChecked(hasSelectedChildFilters);
  };

  /**
   * Triggers when selecting a parent filter
   * @param {CheckboxChangeEventType} [value]
   */
  const handleSelectFilter = (value?: CheckboxChangeEventType): void => {
    if (transformedHierarchyValues) {
      selectedFilterRef.current = [
        item.id,
        transformedHierarchyValues.map(
          (hierarchyValue: DropDownResult) => hierarchyValue.value
        ),
      ];
    }

    if (value?.checked) {
      handleSetSelectedFilters(filterType, selectedFilterRef.current);
      selectedChildFiltersRef.current = [...transformedHierarchyValues];
    } else {
      handleRemoveSelectedFilters(filterType, item.id);
      selectedChildFiltersRef.current = [];
    }

    setIsChecked(!isChecked);
  };

  const handleOnChange = (values: DropDownResult[]) => {
    handleSetParentFilterCheck(values);
  };

  const count = selectedChildFiltersRef.current?.length ?? 0;

  return (
    <div className="CheckboxListFilter" data-checked={String(isToggled)}>
      <div className="CheckboxListFilterHeader">
        <Checkbox
          label={item.caption}
          name={item.caption.replace(/\s+/g, '')}
          initialChecked={isChecked}
          onChange={handleSelectFilter}
        />
        {isChecked && !isToggled && (
          <span className="CheckboxListFilterCount">{count}</span>
        )}
        <IconButton
          onClick={handleFilterToggle}
          aria-label="Toggle"
          classNames="CheckboxListFilterToggle"
        >
          <OdinIcon
            icon={isToggled ? 'ArrowUpS' : 'ArrowDownS'}
            type={OdinIconType.Line}
          />
        </IconButton>
      </div>
      {!isLoading && transformedHierarchyValues && isToggled && (
        <div className="HierarchyCheckboxListFilterBody">
          <DropDownMultiSelect
            data={transformedHierarchyValues}
            placeholder={t('select_filter')}
            textField="text"
            valueField="value"
            multipleMessageCount={11}
            multipleMessageText={t('many_filters', { count })}
            value={selectedChildFiltersRef.current}
            useChips={count <= 10}
            onChange={(val) => handleOnChange(val as DropDownResult[])}
          />
        </div>
      )}
    </div>
  );
};
export default HierarchyCheckboxListFilter;
