import { createRoot } from 'react-dom/client';
import './i18n/i18n-config';

import AppWrapper from 'Components/AppWrapper';
import 'Styles/main.scss';

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<AppWrapper />);
