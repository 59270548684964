import { Loader } from '@myosh/odin-components';
import { LoaderProps } from '@myosh/odin-components/dist/types/components/loader/loader.component';

const Loading = ({ title, content, size }: LoaderProps) => {
  return (
    <div className="odin-flex odin-h-full odin-w-full odin-items-center odin-justify-center">
      <Loader title={title} content={content} size={size} />
    </div>
  );
};

export default Loading;
