import { useCallback, useEffect, useRef, useState } from 'react';
import { DropDown, OdinDateTime } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';

import useAppStateStore from 'Store/AppState.store';
import { calculateDates, formatDate } from 'Helpers/helpers';

import './DateRangeFilter.scss';

interface ISelectedRange {
  value?: number;
  text?: string;
}

export enum DateRangeEnum {
  'last_7_days' = 1,
  'last_30_days',
  'last_12_months',
  'current_month',
  'current_year',
  'next_12_months',
}

function isWithinFiveYears(firstDate?: string, secondDate?: string) {
  if (firstDate && secondDate) {
    const dateFrom = new Date(firstDate);
    const dateTo = new Date(secondDate);

    const fiveYearsLater = new Date(dateFrom);

    fiveYearsLater.setFullYear(fiveYearsLater.getFullYear() + 5);

    return dateTo <= fiveYearsLater;
  }
}

const DateRangeFilter = () => {
  const { t } = useTranslation();
  const {
    handleSetSelectedFilters,
    handleSetCalendarDates,
    handleSetCustomDateRangeError,
  } = useAppStateStore.getState();
  const storedValue = useAppStateStore((s) => s.tempFilters.dateRange);
  const customDateRangeError = useAppStateStore((s) => s.customDateRangeError);

  const { dateStart, dateEnd, isCustomDates } = useAppStateStore(
    (s) => s.tempCalendar
  );

  const calendarModeRef = useRef(useAppStateStore.getState().mode);
  const dateRangeSelectedValueRef = useRef<number | null>(null);

  const dateRangeOptions = Object.keys(DateRangeEnum)
    .filter((v) => Number.isNaN(Number(v)))
    .map((v, i) => ({ value: i + 1, text: t(v) }));

  const handleGetDefaultValue = useCallback(
    () => dateRangeOptions[+storedValue - 1],
    [dateRangeOptions, storedValue]
  );

  const dateFormatted = (date: Date) => formatDate(date);

  const setPickerDates = useCallback(
    (value: number) => {
      const { fromDate, toDate } = calculateDates(
        value,
        calendarModeRef.current
      );

      handleSetCalendarDates({
        dateStart: dateFormatted(fromDate),
        dateEnd: dateFormatted(toDate),
        isCustomDates: value === 0,
      });
    },
    [handleSetCalendarDates]
  );

  const handleOnChangeRange = useCallback(
    (range: unknown): void => {
      const selected: ISelectedRange = range || {};

      if (
        selected.value &&
        dateRangeSelectedValueRef.current !== selected.value
      ) {
        dateRangeSelectedValueRef.current = selected.value;

        handleSetSelectedFilters('dateRange', [selected.value], true);
        setPickerDates(selected.value);
      }
      handleSetCustomDateRangeError(false);
    },
    [setPickerDates, handleSetSelectedFilters]
  );

  const handleOnChangeDate = (dateValue: unknown, field: string) => {
    const date = dateValue as Date;
    if (field === 'from') {
      handleSetCalendarDates({
        dateStart: dateFormatted(date),
      });
    } else if (isWithinFiveYears(dateStart, dateValue as string)) {
      handleSetCalendarDates({
        dateEnd: dateFormatted(date),
      });
      handleSetCustomDateRangeError(false);
    } else {
      handleSetCustomDateRangeError(true);
    }

    handleSetSelectedFilters('dateRange', [0], true);
    handleSetCalendarDates({
      isCustomDates: true,
    });
  };

  // Connect to the store on mount, disconnect on unmount,
  // catch state-changes in a reference
  useEffect(() => {
    useAppStateStore.subscribe(
      // eslint-disable-next-line no-return-assign
      (state) => (calendarModeRef.current = state.mode)
    );
  }, []);

  return (
    <div className="DateRangeFilter">
      <div className="DateRangeFilter__select">
        <DropDown
          inputRef={(node) => {
            const thisNodeRef = node as {
              setInnerText: (text: string) => void;
            };
            if (thisNodeRef && isCustomDates) {
              thisNodeRef.setInnerText(t('custom'));
            }
          }}
          data={dateRangeOptions}
          label={t('quick_date_range')}
          placeholder={t('select_date_range')}
          selectorStyles="odin-text-sm"
          allowClear={false}
          allowSearch={false}
          showAddNewOption={false}
          textField="text"
          valueField="value"
          value={!isCustomDates ? handleGetDefaultValue() : undefined}
          onChange={handleOnChangeRange}
        />
      </div>

      <div className="DateRangeFilter__fromto">
        <OdinDateTime
          label={t('from')}
          value={formatDate(dateStart as string, undefined, true)}
          dateSelectionType={1}
          onChange={(e) => handleOnChangeDate(e, 'from')}
        />
        <OdinDateTime
          label={t('to')}
          value={formatDate(dateEnd as string, undefined, true)}
          dateSelectionType={1}
          onChange={(e) => handleOnChangeDate(e, 'to')}
        />
      </div>
      {customDateRangeError && (
        <p className="DateRangeError">{t('date_range_max_5_years')}</p>
      )}
    </div>
  );
};

export default DateRangeFilter;
