import { useMemo } from 'react';
import { Login } from '@myosh/myosh-login';
import { useLocation } from 'react-router-dom';

export default function LoginPage() {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const username =
    searchParams.get('userName') || searchParams.get('username') || undefined;

  return <Login redirectPath="/" username={username} />;
}
