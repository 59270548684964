import sub from 'date-fns/sub';
import add from 'date-fns/add';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import startOfMonth from 'date-fns/startOfMonth';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfYear from 'date-fns/startOfYear';
import lastDayOfYear from 'date-fns/lastDayOfYear';

import { DATE_PATTERNS, DEFAULT_LIST_MODE, WEEK_VIEW } from './constants';

const isObjectEmpty = (obj: object): boolean => {
  return (
    // because Object.keys(new Date()).length === 0;
    // we have to do some additional check
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

const objectHasKey = (key: string, obj: object): boolean => {
  return key in obj;
};

const convertThemeToCSS = (obj: { [key: string]: string }) => {
  // iterate over each key/value pair and create custom properties
  let result = Object.entries(obj)
    .map(([key, value]) => `--${key}: ${value};`)
    .join('\n');

  result += '--status-3: #fe4646';

  // create a style element and append it to the body
  const style = document.createElement('style');
  style.innerHTML = `:root {\n${result}}`;
  document.body.appendChild(style);
};

const getDate = (date: Date) => date.getDate();
const getMonth = (date: Date) => date.getMonth();
const getYear = (date: Date) => date.getFullYear();

const formatDate = (
  date: Date | string,
  pattern = DATE_PATTERNS.MDY,
  showLocale = false
) => {
  const locale = showLocale ? navigator.language : 'en-US';
  const newDate = typeof date === 'string' ? new Date(date) : date;
  return new Intl.DateTimeFormat(locale, pattern).format(newDate);
};

const calculateDates = (value: number, calendarMode = DEFAULT_LIST_MODE) => {
  const currentDate = new Date();

  let fromDate = currentDate;
  let toDate = currentDate;

  switch (value) {
    case 1:
      fromDate = sub(currentDate, { days: 7 });
      break;

    case 2:
      fromDate = sub(currentDate, { days: getDaysInMonth(currentDate) });
      break;

    case 3:
      if (calendarMode !== WEEK_VIEW) {
        fromDate = startOfMonth(
          sub(currentDate, {
            months: 12,
          })
        );
        toDate = endOfMonth(currentDate);
        break;
      }

      fromDate = startOfWeek(
        sub(currentDate, {
          months: 12,
        }),
        { weekStartsOn: 1 }
      );
      toDate = endOfWeek(currentDate, { weekStartsOn: 1 });
      break;

    case 4:
      fromDate = startOfMonth(currentDate);
      toDate = lastDayOfMonth(currentDate);
      break;

    case 5:
      fromDate = startOfYear(currentDate);
      toDate = lastDayOfYear(currentDate);
      break;

    default:
      if (calendarMode !== WEEK_VIEW) {
        fromDate = startOfMonth(currentDate);
        toDate = endOfMonth(
          add(currentDate, {
            months: 12,
          })
        );
        break;
      }

      fromDate = startOfWeek(currentDate, { weekStartsOn: 1 });
      toDate = endOfWeek(
        add(currentDate, {
          months: 12,
        }),
        { weekStartsOn: 1 }
      );
      break;
  }

  return { fromDate, toDate };
};

const findExistingIndexById = <T extends unknown[]>(
  array: T,
  id: number
): number => {
  if (!array || array.length === 0) return -1;

  return array.findIndex((f) => {
    if (Array.isArray(f)) {
      return f[0] === id;
    }

    return f === id;
  });
};

const toCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

// Merge arrays of objects, and replace objects with similar IDs
const mergeArrays = (
  arr1: Record<string, unknown>[],
  arr2: Record<string, unknown>[]
) => {
  const mergedArray = [...arr1];

  arr2.forEach((obj2) => {
    const existingObjIndex = mergedArray.findIndex(
      (obj1) => obj1.id === obj2.id
    );
    if (existingObjIndex !== -1) {
      mergedArray[existingObjIndex] = obj2;
    } else {
      mergedArray.push(obj2);
    }
  });

  return mergedArray;
};

const pad = (n: number) => (n < 10 ? `0${n}` : n);

export {
  isObjectEmpty,
  objectHasKey,
  convertThemeToCSS,
  getDate,
  getMonth,
  getYear,
  formatDate,
  calculateDates,
  findExistingIndexById,
  toCamelCase,
  mergeArrays,
  pad,
};
