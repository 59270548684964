import { ENDPOINTS } from 'Helpers/constants';
import { convertThemeToCSS } from 'Helpers/helpers';
import useFetchData from 'Hooks/useFetchData';

const useTheme = () => {
  const { data, error, isLoading } = useFetchData(ENDPOINTS.THEME);

  if (data) convertThemeToCSS(data);

  return {
    themeLoading: isLoading || (error && error.ok),
  };
};

export default useTheme;
