import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'Static/locales/en/translation.json';
import fr from 'Static/locales/fr/translation.json';

i18n.use(initReactI18next).init({
  lng: navigator ? navigator.language.substring(0, 2) : 'en',
  fallbackLng: 'en',
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
  ns: ['translation'],
  defaultNS: 'translation',
  returnNull: false,
});

export default i18n;
