import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckboxListFilter from 'Components/common/CheckboxListFilter/CheckboxListFilter';
import FilterShowMore from 'Components/common/FilterShowMore/FilterShowMore';

import { ENDPOINTS } from 'Helpers/constants';
import { objectHasKey } from 'Helpers/helpers';
import useFetchData from 'Hooks/useFetchData';

type ResourceData = Record<string, unknown>;

interface ProcessedData {
  id: number;
  caption: string;
  body: ResourceData;
}

const FormsFilter = () => {
  const { t } = useTranslation();
  const { isLoading, data } = useFetchData(ENDPOINTS.MODULES);
  const [modules, setModules] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const isMountedRef = useRef(true);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (isMountedRef && data) {
      const filteredModules = data.items
        .filter(
          (d: ResourceData) =>
            !d.removed && !d.hidden && objectHasKey('forms', d)
        )
        .map((d: ResourceData) => ({
          id: d.id,
          caption: d.caption,
          body: d.forms,
        }));

      setModules(filteredModules);
    }

    return () => {
      isMountedRef.current = false;
    };
  }, [data]);

  return (
    <div className="Filter FormsFilter">
      <h3 className="FilterTitle">{t('module')}</h3>

      {isLoading ? (
        <div className="odin-text-sm">{t('getting_data')}</div>
      ) : (
        <>
          {modules.map((module: ProcessedData, index: number) => {
            if (!showAll && index > 9) return false;
            return (
              <CheckboxListFilter
                key={module.id}
                filterType="forms"
                item={module}
              />
            );
          })}

          <FilterShowMore
            shouldLimitView={modules.length > 10}
            isExpanded={showAll}
            onExpandFilter={handleShowAll}
            showAllText={t('show_all_modules')}
            showLessText={t('show_less_modules')}
          />
        </>
      )}
    </div>
  );
};

export default FormsFilter;
