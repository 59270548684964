import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';

import i18next from 'Src/i18n/i18n-config';

import { DATE_PATTERNS, MONTH_VIEW, WEEK_VIEW, YEAR_VIEW } from './constants';
import {
  formatDate,
  getDate,
  getMonth,
  getYear,
  pad,
  toCamelCase,
} from './helpers';

type ObjectType = Record<string, any>;

let dateStart: string;
let dateEnd: string;
let columnOptions: object;

const getScheduleCellRenderContent = (field: string) => {
  return {
    renderer({ record }: ObjectType) {
      return `<a href="${record.data.link}" target="_blank" title="${i18next.t(
        'edit_schedule'
      )}">${record.data[field]}</a>`;
    },
    htmlEncode: false,
  };
};

const getCellRendererContent = (records: ObjectType[]) => {
  let className =
    records.length > 1 ? 'Schedules__record-count' : 'Schedules__record';
  let content = '';

  if (records.length > 1) {
    content = records.length.toString();

    const hasOverdueRecord = records.some((rec) => rec.status === 'overdue');
    const hasInProgressRecord = records.some(
      (rec) => rec.status === 'progress' && !hasOverdueRecord
    );
    const hasCompletedRecord = records.some(
      (rec) =>
        rec.status === 'completed' && !hasOverdueRecord && !hasInProgressRecord
    );
    const hasFutureRecord =
      !hasOverdueRecord && !hasInProgressRecord && !hasCompletedRecord;

    if (hasOverdueRecord) {
      className += ` Schedules__record-count--overdue`;
    } else if (hasInProgressRecord) {
      className += ` Schedules__record-count--progress`;
    } else if (hasCompletedRecord) {
      className += ` Schedules__record-count--completed`;
    } else if (hasFutureRecord) {
      className += ` Schedules__record-count--future`;
    }
  }

  if (records.length === 1) {
    const text = formatDate(records[0].date, {}, true);
    className += ` Schedules__record--${records[0].status}`;

    if (records[0].status === 'future') {
      content = text;
    } else {
      content = `<a href="${records[0].link}" target="_blank" 
        title="${i18next.t('open_record')}">
          ${text}
        </a>`;
    }
  }

  return `
    <div class="${className}" ${
    records.length > 1 ? `data-records='${JSON.stringify(records)}'` : ''
  }>
      ${content}
    </div>
  `;
};

const cellRenderer = (
  record: ObjectType,
  arr: string[] | string,
  mode: string
) => {
  const records: Record<string, string>[] = [];

  record?.data?.data.forEach((data: ObjectType) => {
    const currentDate = data.calendarViewDate.slice(0, 10);
    let currentRecord = '';

    if (mode === WEEK_VIEW) {
      currentRecord = formatDate(currentDate);
    } else if (mode === MONTH_VIEW) {
      currentRecord = formatDate(currentDate, DATE_PATTERNS.MY, true);
    } else if (mode === YEAR_VIEW) {
      currentRecord = formatDate(currentDate, DATE_PATTERNS.Y);
    }

    if (arr.includes(currentRecord)) {
      records.push({
        date: currentDate,
        status: data.status,
        link: data.link,
      });
    }
  });

  return getCellRendererContent(records);
};

const columnRenderers = (mode: string, dates: string[] | string) => {
  return {
    renderer({ record }: ObjectType) {
      return cellRenderer(record, dates, mode);
    },
    tooltipRenderer({ cellElement }: ObjectType) {
      const recordsArr = JSON.parse(cellElement.dataset.records);
      let listContent = '';
      if (recordsArr.length > 1) {
        recordsArr.forEach((record: Record<string, string>) => {
          const text = formatDate(record.date, {}, true);
          listContent += `<li class="odin-pb-2 last:odin-pb-0 Schedules__record Schedules__record--${record.status}">`;

          if (record.status === 'future') {
            listContent += `${text}</li>`;
          } else {
            listContent += `<a href="${
              record.link
            }" target="_blank" title="${i18next.t('open_record')}">
              ${text}
            </a></li>`;
          }
        });
      }

      return `<div><ul>${listContent}</ul></div>`;
    },
  };
};

// Return Array of Years based on start/end Dates
const generateYears = () => {
  let startYear = getYear(new Date(dateStart));
  const endYear = getYear(new Date(dateEnd));
  const years = [];

  while (startYear <= endYear) {
    years.push(startYear.toString());
    startYear += 1;
  }

  return years;
};

// Return Array of Months based on start/end Dates
const generateMonths = () => {
  const startMonth = startOfMonth(new Date(dateStart));
  const endMonth = endOfMonth(new Date(dateEnd));
  const months = [];

  while (startMonth <= endMonth) {
    const month = formatDate(startMonth, DATE_PATTERNS.MY, true);
    months.push(month);
    startMonth.setMonth(startMonth.getMonth() + 1);
  }

  return months;
};

// Return Array of Weeks based on start/end Dates
const generateWeeks = () => {
  const startWeekDay = startOfWeek(new Date(dateStart), { weekStartsOn: 1 });
  const endWeekDay = endOfWeek(new Date(dateEnd), { weekStartsOn: 1 });
  const weeks = [];
  let currentWeek = [];

  while (startWeekDay <= endWeekDay) {
    currentWeek.push(formatDate(startWeekDay));
    startWeekDay.setDate(getDate(startWeekDay) + 1);

    if (startWeekDay.getDay() === 1 && currentWeek.length > 0) {
      weeks.push(currentWeek);
      currentWeek = [];
    }
  }

  if (currentWeek.length > 0) {
    weeks.push(currentWeek);
  }

  return weeks;
};

// Aggregate Column Data from Returned Years Array
const generateYearColumns = (mode: string) => {
  const yearsArr = generateYears();

  return yearsArr.map((year) => ({
    id: year,
    text: year,
    ...columnOptions,
    ...columnRenderers(mode, year),
    htmlEncode: false,
  }));
};

// Aggregate Column Data from Returned Months Array
const generateMonthColumns = (mode: string) => {
  const monthsArr = generateMonths();

  return monthsArr.map((month) => ({
    id: month.replace(' ', '_'),
    text: month,
    ...columnOptions,
    ...columnRenderers(mode, month),
    htmlEncode: false,
  }));
};

// Aggregate Column Data from Returned Weeks Array
const generateWeekColumns = (mode: string) => {
  const weeksArr = generateWeeks();

  return weeksArr.map((week, index) => ({
    id: `${week[0]}_${week[week.length - 1]}`,
    text: `${formatDate(week[0], {}, true)} -<br />${formatDate(
      week[week.length - 1],
      {},
      true
    )}`,
    ...columnOptions,
    ...columnRenderers(mode, weeksArr[index]),
    htmlEncodeHeaderText: false,
    htmlEncode: false,
  }));
};

// Returns Array of Dates Columns based on Mode
const generateDateColumns = (
  start: string,
  end: string,
  mode: string,
  options: object
) => {
  dateStart = start;
  dateEnd = end;
  columnOptions = options;

  if (mode === MONTH_VIEW) {
    return generateMonthColumns(mode);
  }

  if (mode === YEAR_VIEW) {
    return generateYearColumns(mode);
  }

  return generateWeekColumns(mode);
};

// Returns Array of Hierarchy Type Columns
const generateHierarchyColumns = (
  hierarchyTypes: string[],
  options: object
) => {
  return hierarchyTypes?.map((type) => ({
    id: toCamelCase(type),
    field: toCamelCase(type),
    text: type,
    hidden: true,
    ...options,
  }));
};

// Returns Array of Hidden Built-in Columns
const generateHiddenColumns = (keys: string[]) => {
  return keys.map((key) => ({
    id: key,
    field: key,
    hidden: true,
    hideable: false,
  }));
};

// Returns Array of Hidden Built-in Columns
const generateGeneralColumns = (
  columns: Record<string, string>[],
  options: object
) => {
  return columns.map((col) => ({
    id: col.field,
    field: col.field,
    text: col.label,
    ...options,
    // Temporarily removing link rendering for Schedules
    // ...(col.field === 'schedule'
    //   ? getScheduleCellRenderContent(col.field)
    //   : null),
  }));
};

const filterDate = (date: string, convertToISO = false) => {
  const newDate = new Date(date);

  if (convertToISO) {
    return new Date(
      newDate.getTime() - newDate.getTimezoneOffset() * 60000
    ).toISOString();
  }

  return `${getYear(newDate)}-${pad(getMonth(newDate) + 1)}-${pad(
    getDate(newDate)
  )}`;
};

interface Caption {
  translations: Array<CaptionTranslation>;
}

interface CaptionTranslation {
  localeId: number;
  value: string;
}

interface HierarchyField {
  id: number;
  hierarchyTypeId: number;
  caption: Caption;
  hierarchyTypeCaption: Caption;
}

const getHierarchyValues = (hierarchyFields: Array<HierarchyField>) => {
  const hierarchyData: Record<string, string> = {};

  for (let i = 0, total = hierarchyFields.length; i < total; i += 1) {
    const { caption, hierarchyTypeCaption } = hierarchyFields[i];

    const key = hierarchyTypeCaption?.translations?.[0]?.value ?? '';
    const { value } = caption?.translations?.[0] ?? '';

    if (key && value) {
      hierarchyData[key.toLowerCase()] = value;
    }
  }

  return hierarchyData;
};

const getStartEndDateValues = (records: ObjectType[]) => {
  let startDate = new Date().toISOString();
  let endDate = new Date().toISOString();

  if (records) {
    startDate = records[0].createdAt;

    if (records.length === 1) {
      endDate = startDate;
    } else {
      endDate = records[records.length - 1].createdAt;
    }
  }

  return { startDate, endDate };
};

const getRecordStatus = (status: string) => {
  switch (status) {
    case 'Overdue':
      return 'overdue';
    case 'Closed':
      return 'completed';
    default:
      return 'progress';
  }
};

const getRecordsValues = (
  records: ObjectType[],
  containsHistoricalRecords: boolean
) => {
  return records
    ? records.map((rec: ObjectType) => {
        let status = rec.status?.translations[0].value;

        if (containsHistoricalRecords) {
          // If record is not a projection (past date),
          // use the status label, else, status === future
          status = !rec.projected ? getRecordStatus(status) : 'future';
        } else {
          // If record is in a past date (regardless of projection value),
          // use the status label, else, status === future
          const isRecordPastDate = new Date(rec.createdAt) < new Date();
          status = isRecordPastDate ? getRecordStatus(status) : 'future';
        }

        return {
          calendarViewDate: rec.createdAt,
          status,
          link: rec.linkUrl || '',
        };
      })
    : [];
};

const transformScheduleData = (items: ObjectType[]) => {
  if (!items) return [];

  return items.reduce((acc, curr) => {
    const { schedule, records, containsHistoricalRecords } = curr;
    const hiearchyData = getHierarchyValues(schedule.hierarchyFields ?? []);
    const recordData = getRecordsValues(records, containsHistoricalRecords);
    const { startDate, endDate } = getStartEndDateValues(records);

    acc.push({
      id: schedule.id,
      description: schedule.caption?.translations[0].value || '-',
      startDate,
      endDate,
      ...hiearchyData,
      form: schedule.moduleFormCaption?.translations[0].value || '',
      schedule: schedule.rules?.[0]?.interval || '',
      link: schedule.linkUrl || '',
      data: recordData,
    });

    return acc;
  }, []) as object[];
};

export {
  generateDateColumns,
  generateHierarchyColumns,
  generateHiddenColumns,
  generateGeneralColumns,
  transformScheduleData,
  filterDate,
  getScheduleCellRenderContent,
};
