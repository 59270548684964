import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type ObjectType = Record<string, unknown>;

type State = {
  leadColumns: ObjectType[];
};

type Action = {
  handleSetColumnState: (columns: ObjectType[]) => void;
};

const useColumnStateStore = create<State & Action>()(
  devtools(
    persist(
      (set) => ({
        leadColumns: [],
        handleSetColumnState: (columns: ObjectType[]) => {
          set(() => ({
            leadColumns: columns,
          }));
        },
      }),
      {
        name: 'column-state',
        partialize: ({ leadColumns }) => ({
          leadColumns,
        }),
      }
    )
  )
);

export default useColumnStateStore;
