import { DropDownMultiSelect, DropDownResult } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';

import './CheckboxListFilterBody.scss';

const CheckboxListFilterBody = ({ items, value, onSelectChildFilter }: any) => {
  const { t } = useTranslation();
  const handleOnChange = (values: DropDownResult[]) => {
    onSelectChildFilter(values?.map((v) => v.value));
  };

  return (
    <div className="CheckboxListFilterBody">
      <DropDownMultiSelect
        data={items}
        placeholder={t('select_filter')}
        textField="caption"
        valueField="id"
        multipleMessageCount={11}
        multipleMessageText={t('many_filters', { count: value.length })}
        value={value}
        useChips={value.length <= 10}
        onChange={(val) => handleOnChange(val as DropDownResult[])}
      />
    </div>
  );
};

export default CheckboxListFilterBody;
