/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';

class ErrorBoundary extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { error, errorInfo } = this.state;

    if (errorInfo) {
      return (
        <div>
          <h3>Ooops! Something went wrong.</h3>

          <details open style={{ whiteSpace: 'pre-wrap' }}>
            {error && <p>The error: {error.toString()}</p>}
            <p>Where it occurred: {errorInfo.componentStack}</p>
          </details>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
