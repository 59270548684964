import { useCallback, useEffect, useRef, useState } from 'react';

import Gutter from 'Common/Gutter/Gutter';
import PanelToggle from 'Common/PanelToggle/PanelToggle';
import Filters from 'Modules/Filters/Filters';

import DateRangeFilter from 'Components/modules/DateRangeFilter/DateRangeFilter';
import FiltersAction from 'Components/modules/Filters/FiltersAction';

import './Sidebar.scss';

const Sidebar = () => {
  const [isToggled, setIsToggled] = useState(false);
  const sidebarRef = useRef<HTMLInputElement>(null);
  const gutterRef = useRef<HTMLInputElement>(null);

  const handlePanelToggle = useCallback(() => {
    setIsToggled(!isToggled);
    sidebarRef?.current?.style.setProperty(
      '--Sidebar-width',
      !isToggled ? '0' : '300px'
    );
  }, [isToggled]);

  const handleResize = useCallback(
    (e: PointerEvent) => {
      e.preventDefault();
      const sidebar = sidebarRef?.current?.getBoundingClientRect();
      const prevX = e?.x || 0;

      const mousemove = (ev: PointerEvent) => {
        const newX = prevX - ev.x;
        const newWidth = sidebar ? sidebar.width - newX : 0;

        if (isToggled) {
          handlePanelToggle();
          return;
        }

        if (sidebarRef.current && newWidth > 260 && newWidth < 500) {
          sidebarRef.current.style.setProperty(
            '--Sidebar-width',
            `${newWidth}px`
          );
        }
      };

      const mouseup = () => {
        window.removeEventListener('pointermove', mousemove);
        window.removeEventListener('pointerup', mouseup);
      };

      window.addEventListener('pointermove', mousemove);
      window.addEventListener('pointerup', mouseup);
    },
    [handlePanelToggle, isToggled]
  );

  useEffect(() => {
    let gutterRefCurrent = gutterRef?.current;

    if (gutterRef?.current) {
      gutterRefCurrent = gutterRef?.current;
      gutterRef?.current?.addEventListener('pointerdown', handleResize);
    }

    return () => {
      gutterRefCurrent?.removeEventListener('pointerdown', handleResize);
    };
  }, [gutterRef, handleResize]);

  return (
    <div
      className="Sidebar custom-scroll"
      ref={sidebarRef}
      data-toggled={String(isToggled)}
    >
      <DateRangeFilter />
      <Filters />
      <FiltersAction />
      <Gutter gutterRef={gutterRef} />
      <PanelToggle isToggled={isToggled} onToggle={handlePanelToggle} />
    </div>
  );
};

export default Sidebar;
