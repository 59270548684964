import React, { useState } from 'react';
import { BryntumCalendar } from '@bryntum/calendar-react';
import { useTranslation } from 'react-i18next';
import { LIST_VIEW_MODES } from 'Helpers/constants';

import './ModeSelector.scss';

export interface ModeSelectorProps {
  calendar: () => BryntumCalendar | null;
}

const ModeSelector = ({ calendar }: ModeSelectorProps) => {
  const [activeMode, setActiveMode] = useState<string>();
  const { t } = useTranslation();

  if (calendar()?.instance && !activeMode) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setActiveMode(calendar()!.instance.mode);
  }

  return (
    <div className="ModeSelector">
      {LIST_VIEW_MODES.map((mode) => {
        return (
          <button
            key={mode}
            type="button"
            className="ModeSelector__button"
            data-active={calendar()?.instance.mode === mode}
            onClick={() => {
              if (calendar()?.instance) {
                // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-non-null-assertion
                calendar()!.instance.mode = mode;

                setActiveMode(mode);
              }
            }}
          >
            {t(mode)}
          </button>
        );
      })}
    </div>
  );
};

export default ModeSelector;
