import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Checkbox,
  CheckboxChangeEventType,
  DropDownResult,
  IconButton,
  OdinIcon,
  OdinIconType,
} from '@myosh/odin-components';

import useAppStateStore from 'Store/AppState.store';

import { findExistingIndexById } from 'Helpers/helpers';
import CheckboxListFilterBody from '../CheckboxListFilterBody/CheckboxListFilterBody';

import './CheckboxListFilter.scss';

type CheckboxListFilterItemType = {
  id: number;
  caption: string;
  body: Record<string, any>;
};

type CheckboxListFilterType = {
  filterType: string;
  item: CheckboxListFilterItemType;
};

const CheckboxListFilter = ({ filterType, item }: CheckboxListFilterType) => {
  const [isToggled, setIsToggled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const storedSelectedFilters = useAppStateStore(
    (s) => s.tempFilters[filterType]
  );
  const { handleSetSelectedFilters, handleRemoveSelectedFilters } =
    useAppStateStore.getState();

  const selectedFilterRef = useRef<(number | number[])[]>([]);
  const selectedChildFiltersRef = useRef<DropDownResult[]>();

  const mapChildFilters = useCallback(
    (arr: CheckboxListFilterItemType['body']) => {
      return arr.map((i: CheckboxListFilterItemType['body']) => ({
        value: i.id,
        text: i.caption,
      }));
    },
    []
  );

  const filters: DropDownResult[] = useMemo(() => {
    return mapChildFilters(item.body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.body]);

  useEffect(() => {
    const storedFilterIndex = findExistingIndexById(
      storedSelectedFilters,
      item.id
    );

    setIsChecked(storedFilterIndex !== -1);

    if (storedFilterIndex !== -1) {
      const storedChildFilters = storedSelectedFilters[storedFilterIndex];
      const storedChildFilterIndexes = Array.isArray(storedChildFilters)
        ? storedChildFilters[1]
        : [];

      if (storedChildFilterIndexes.length > 0) {
        selectedChildFiltersRef.current = mapChildFilters(
          item.body.filter((i: CheckboxListFilterItemType['body']) =>
            storedChildFilterIndexes.includes(i.id)
          )
        );
      }
    } else {
      selectedChildFiltersRef.current = [];
      selectedFilterRef.current = [];
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, item.body, item.id, storedSelectedFilters]);

  /**
   * Triggers when selecting child filter/s
   * @param {number[]} childFilters
   */
  const handleSetParentFilterCheck = (childFilters: number[]): void => {
    const hasSelectedChildFilters = childFilters.length > 0;

    if (hasSelectedChildFilters) {
      selectedFilterRef.current = [item.id, childFilters];
      handleSetSelectedFilters(filterType, selectedFilterRef.current);
    } else {
      handleRemoveSelectedFilters(filterType, item.id);
      selectedFilterRef.current = [];
    }

    selectedChildFiltersRef.current = mapChildFilters(
      item.body.filter((i: CheckboxListFilterItemType['body']) =>
        childFilters.includes(i.id)
      )
    );

    setIsChecked(hasSelectedChildFilters);
  };

  /**
   * Triggers when selecting a parent filter
   * @param {CheckboxChangeEventType} [value]
   */
  const handleSelectFilter = (value?: CheckboxChangeEventType): void => {
    setIsChecked(!isChecked);

    selectedFilterRef.current = [
      item.id,
      filters.map((filter: DropDownResult) => filter.value as number),
    ];

    if (value?.checked) {
      handleSetSelectedFilters(filterType, selectedFilterRef.current);
      selectedChildFiltersRef.current = filters;
    } else {
      handleRemoveSelectedFilters(filterType, item.id);
      selectedChildFiltersRef.current = [];
    }
  };

  const handleFilterToggle = (): void => {
    setIsToggled(!isToggled);
  };

  return (
    <div className="CheckboxListFilter" data-checked={String(isToggled)}>
      <div className="CheckboxListFilterHeader">
        <Checkbox
          label={item.caption}
          name={item.caption.replace(/\s+/g, '')}
          initialChecked={isChecked}
          onChange={handleSelectFilter}
        />
        {isChecked && !isToggled && (
          <span className="CheckboxListFilterCount">{`+${
            selectedChildFiltersRef.current?.length ?? 0
          }`}</span>
        )}
        <IconButton
          onClick={handleFilterToggle}
          aria-label="Toggle"
          classNames="CheckboxListFilterToggle"
        >
          <OdinIcon
            icon={isToggled ? 'ArrowUpS' : 'ArrowDownS'}
            type={OdinIconType.Line}
          />
        </IconButton>
      </div>
      {isToggled && (
        <CheckboxListFilterBody
          items={item.body}
          onSelectChildFilter={handleSetParentFilterCheck}
          value={selectedChildFiltersRef.current}
        />
      )}
    </div>
  );
};
export default CheckboxListFilter;
