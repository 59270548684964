import React, { useCallback, useRef, useState } from 'react';
import {
  DomElementAlignment,
  OdinIcon,
  OdinIconType,
  OverlayPanel,
} from '@myosh/odin-components';
import { BryntumCalendar } from '@bryntum/calendar-react';
import Settings from './Settings';

import './Settings.scss';

export interface SettingsOverlayProps {
  calendar: () => BryntumCalendar | null;
}

const SettingsOverlay = ({ calendar }: SettingsOverlayProps) => {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const settingsRef = useRef<HTMLButtonElement>(null);

  const closeOverlay = useCallback(() => {
    setOverlayVisible(false);
  }, []);

  return (
    <>
      <button
        ref={settingsRef}
        type="button"
        onClick={() => setOverlayVisible(true)}
        aria-label="Settings"
      >
        <OdinIcon icon="Settings3" type={OdinIconType.Line} />
      </button>
      <OverlayPanel
        visible={overlayVisible}
        target={settingsRef.current}
        elementAlignment={DomElementAlignment.TopRight}
        hidden={closeOverlay}
        shouldCheckZIndex
        shouldRemainOnScreen
        shouldUseCreatePortal={false}
      >
        <Settings calendar={calendar()} onSave={closeOverlay} />
      </OverlayPanel>
    </>
  );
};

export default SettingsOverlay;
