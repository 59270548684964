import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import HierarchyCheckboxListFilter from 'Components/common/CheckboxListFilter/HierarchyCheckboxListFilter';
import FilterShowMore from 'Components/common/FilterShowMore/FilterShowMore';
import useAppStateStore from 'Store/AppState.store';

import { ENDPOINTS } from 'Helpers/constants';
import useFetchData from 'Hooks/useFetchData';

interface HierarchyType {
  id: number;
  caption: string;
  weight: number;
  version: number;
  archived: boolean;
  external: boolean;
}

const HierarchyFilter = () => {
  const { handleSetHierarchyTypes } = useAppStateStore.getState();

  const { isLoading, data: { result: hierarchyTypes } = {} } = useFetchData(
    `${ENDPOINTS.HIERARCHY_TYPES}?filter=archived:eq:false`
  );

  const { t } = useTranslation();

  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (!isLoading && hierarchyTypes) {
      handleSetHierarchyTypes(
        hierarchyTypes.map((item: HierarchyType) => item.caption)
      );
    }
  }, [isLoading, hierarchyTypes, handleSetHierarchyTypes]);

  return (
    <div className="Filter HierarchyFilter">
      <h3 className="FilterTitle">{t('hierarchy')}</h3>

      {isLoading ? (
        <div className="odin-text-sm">{t('getting_data')}</div>
      ) : (
        <>
          {hierarchyTypes?.map((item: HierarchyType, index: number) => {
            if (!showAll && index > 9) return false;
            return (
              <HierarchyCheckboxListFilter
                key={item.id}
                filterType="hierarchy"
                item={item}
              />
            );
          })}
          <FilterShowMore
            shouldLimitView={hierarchyTypes && hierarchyTypes.length > 10}
            isExpanded={showAll}
            onExpandFilter={handleShowAll}
            showAllText={t('show_all_hierarchies')}
            showLessText={t('show_less_hierarchies')}
          />
        </>
      )}
    </div>
  );
};

export default HierarchyFilter;
