import { useCallback, useEffect, useState } from 'react';
import { OdinIcon, OdinIconSize } from '@myosh/odin-components';

import useAppStateStore from 'Store/AppState.store';

import './Toast.scss';

const Toast = () => {
  const toastList = useAppStateStore((s) => s.notifications);
  const [list, setList] = useState<Record<string, string | number>[]>([]);

  const deleteToast = useCallback(
    (id: number) => {
      const listItemIndex = list.findIndex((e) => e.id === id);
      const toastListItem = toastList.findIndex((e) => e.id === id);
      list.splice(listItemIndex, 1);
      toastList.splice(toastListItem, 1);
      setList([...list]);
    },
    [list, toastList]
  );

  useEffect(() => {
    setList([...toastList]);
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (list.length) {
        deleteToast(toastList[0].id as number);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, list, deleteToast]);

  return (
    <div className="Toast-container">
      {list.map((toast: Record<string, string | number>) => (
        <div key={toast.id} className={`Toast ${toast.type}`}>
          <button
            type="button"
            className="Toast-close"
            onClick={() => deleteToast(toast.id as number)}
          >
            <OdinIcon icon="CloseCircle" size={OdinIconSize.Small} />
          </button>
          <div className="Toast-message">{toast.description}</div>
        </div>
      ))}
    </div>
  );
};

export default Toast;
