import Schedules from 'Components/modules/Schedules/Schedules';

import './Main.scss';

const Main = () => {
  return (
    <div className="Main">
      <Schedules />
    </div>
  );
};

export default Main;
