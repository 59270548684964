import { API_CONFIG } from './constants';

interface FetchProps {
  url: string;
  method?: string;
  data?: object;
}

const fetcher = async ({
  url,
  method = 'GET',
  data = {},
}: FetchProps): Promise<any> => {
  if (!url) {
    return null;
  }

  const { URL: baseUrl, KEY } = API_CONFIG;

  const userState = JSON.parse(localStorage.getItem('UserState') || '{}');
  const USER_SCHEMA = userState?.user?.currentSchema;
  const USER_TOKEN = userState?.tokens?.id_token;

  if (!USER_TOKEN) {
    throw new Error('Cannot fetch data.');
  }

  const args: Record<string, unknown> = {};
  args.method = method;
  args.headers = {
    Authorization: `Bearer ${USER_TOKEN}`,
    'x-api-key': KEY,
    'myosh-schema': USER_SCHEMA,
    'content-type': 'application/json',
  };

  if (method === 'POST') {
    args.body = JSON.stringify(data);
  }

  const response = await fetch(`${baseUrl}/${url}` as RequestInfo, args);
  if (!response.ok) {
    // Intentionally cast to any to add extra info
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error: any = new Error('An error occurred while fetching the data.');
    error.info = await response.json();
    error.status = response.status;
    error.ok = response.ok;
    throw error;
  }

  return response.json();
};

export default fetcher;
