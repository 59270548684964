import {
  generateDateColumns,
  generateGeneralColumns,
  generateHiddenColumns,
  generateHierarchyColumns,
} from 'Helpers/scheduler';
import useColumnStateStore from 'Store/ColumnState.store';
import { useCallback } from 'react';
import { t } from 'i18next';
import { CalendarType } from 'Store/AppState.store';

const columnOptions = {
  leads: {
    width: 140,
    region: 'normal',
    hideable: true,
    hidden: true,
    filterable: {
      filterField: {
        placeholder: t('filter_by'),
      },
    },
  },
  dates: {
    width: 100,
    field: 'date',
    sortable: false,
    hideable: false,
    filterable: false,
  },
};

const generalColumns = generateGeneralColumns(
  [
    { label: t('form'), field: 'form' },
    { label: t('description'), field: 'description' },
    { label: t('schedule'), field: 'schedule' },
  ],
  {
    ...columnOptions.leads,
    region: 'locked',
    hidden: false,
  }
);

const hiddenColumns = generateHiddenColumns([
  'name',
  'startDate',
  'endDate',
  'resources',
]);

// Provides the columns configuration for the calendar
const useColumnsConfig = (
  leadColumns: Record<string, unknown>[],
  calendar: CalendarType,
  hierarchyTypes?: string[]
) => {
  const { handleSetColumnState } = useColumnStateStore.getState();
  const { dateStart, dateEnd } = calendar;

  const handleGenerateColumns = useCallback(
    (modeName: string) => {
      if (!hierarchyTypes) {
        return [];
      }

      const datesColumns = generateDateColumns(
        dateStart as string,
        dateEnd as string,
        modeName as string,
        columnOptions.dates
      );

      if (leadColumns.length > 0) {
        // if previously columns have been generated, return early
        return [...leadColumns, ...datesColumns];
      }

      const hierarchyColumns = generateHierarchyColumns(
        hierarchyTypes,
        columnOptions.leads
      );

      handleSetColumnState([
        ...hierarchyColumns,
        ...generalColumns,
        ...hiddenColumns,
      ]);

      return [
        ...hierarchyColumns,
        ...generalColumns,
        ...datesColumns,
        ...hiddenColumns,
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [leadColumns, dateEnd, dateStart, handleSetColumnState, hierarchyTypes]
  );

  return {
    handleGenerateColumns,
  };
};

export default useColumnsConfig;
