import Spinner from 'Components/common/Spinner/Spinner';

import './StatsCard.scss';

type PropTypes = {
  type: string;
  label: string;
  value: string;
  isLoading: boolean;
};

const StatsCard = ({ type, label, value, isLoading }: PropTypes) => {
  return (
    <div className="StatsCard">
      <div className="StatsCard__type">{label}</div>
      <div className="StatsCard__count" data-type={type}>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="StatsCard__value">{value}</div>
        )}
      </div>
    </div>
  );
};

export default StatsCard;
