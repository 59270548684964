import useSWR from 'swr';
import { AuthService } from '@myosh/myosh-login';
import { useTranslation } from 'react-i18next';

import fetcher from 'Helpers/fetcher';
import useAppStateStore from 'Store/AppState.store';

const authService = new AuthService();

const signinSilent = async () => {
  return authService.signinSilent();
};

const useFetchData = (
  url?: string,
  options?: { [key: string]: boolean | number }
) => {
  const { t } = useTranslation();
  const { handleSetNotifs } = useAppStateStore.getState();

  const showSessionExpiredNotificationAndLogout = () => {
    handleSetNotifs({
      id: 'session-expired',
      type: 'warning',
      description: t('session_expired'),
    });

    setTimeout(() => {
      localStorage.clear();
      authService.logout();
    }, 2000);
  };

  return useSWR({ url }, fetcher, {
    ...options,
    onErrorRetry: (err, _key, _config, revalidate, { retryCount }) => {
      if (err.status !== 401) {
        handleSetNotifs({
          id: Math.floor(Math.random() * 101 + 1),
          type: 'error',
          description: err.message,
        });

        return;
      }

      if (err.status === 401 && retryCount <= 3) {
        signinSilent()
          .then((userData) => {
            if (!userData.refresh_token) {
              showSessionExpiredNotificationAndLogout();

              return;
            }

            const userState = JSON.parse(
              localStorage.getItem('UserState') || '{}'
            );

            userState.tokens = {
              ...userState.tokens,
              id_token: userData.id_token,
              access_token: userData.access_token,
              refresh_token: userData.refresh_token,
              expires_at: userData.expires_at,
              auth_time: Date.now(),
            };

            localStorage.setItem('UserState', JSON.stringify(userState));

            setTimeout(() => revalidate({ retryCount }));
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
          });
      }
    },
  });
};

export default useFetchData;
