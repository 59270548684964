/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckboxChangeEventType,
  IconButton,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
} from '@myosh/odin-components';
import { BryntumCalendar } from '@bryntum/calendar-react';
import { useTranslation } from 'react-i18next';

import { mergeArrays } from 'Helpers/helpers';
import { LIST_VIEW_MODES } from 'Helpers/constants';

import useColumnStateStore from 'Store/ColumnState.store';

interface SettingsProps {
  calendar: BryntumCalendar | null;
  onSave: () => void;
}

const Settings = ({ calendar, onSave }: SettingsProps) => {
  const { t } = useTranslation();

  const [columnSetting, setColumnSetting] = useState<any[]>();
  const [showAll, setShowAll] = useState(false);
  const [isSettingsDirty, setIsSettingsDirty] = useState<boolean>(false);

  const columnStateRef = useRef(useColumnStateStore.getState().leadColumns);

  if (
    !columnSetting &&
    columnStateRef.current &&
    columnStateRef.current.length > 0
  ) {
    setColumnSetting(
      columnStateRef.current.filter(
        (column: Record<string, unknown>) =>
          column.field !== 'date' && column.hideable
      )
    );
  }

  useEffect(() => {
    setShowAll(
      columnSetting?.every(
        (column: Record<string, unknown>) => !column.hidden
      ) ?? false
    );
  }, [columnSetting]);

  const handleShowAll = (value?: CheckboxChangeEventType): void => {
    setColumnSetting(
      columnSetting?.reduce((acc, curr) => {
        acc.push({
          ...curr,
          hidden: !value?.checked,
          ...(curr.region === 'locked' &&
            !value?.checked && { region: 'normal' }),
        });
        return acc;
      }, [])
    );
    setIsSettingsDirty(true);
  };

  const handleShowColumn = (value?: CheckboxChangeEventType): void => {
    setColumnSetting(
      columnSetting?.reduce((acc, curr) => {
        acc.push({
          ...curr,
          ...(curr.id === value?.name && { hidden: !value?.checked }),
        });
        return acc;
      }, [])
    );
    setIsSettingsDirty(true);
  };

  const handlePinColumn = (id: string, region: string): void => {
    setColumnSetting(
      columnSetting?.reduce((acc, curr) => {
        acc.push({
          ...curr,
          ...(curr.id === id && curr.hidden && { hidden: region !== 'normal' }),
          ...(curr.id === id && {
            region: region === 'normal' ? 'locked' : 'normal',
          }),
        });
        return acc;
      }, [])
    );
    setIsSettingsDirty(true);
  };

  const handleSaveSetting = () => {
    LIST_VIEW_MODES.forEach((mode) => {
      const calendarInstance = calendar?.instance?.modes[mode];
      const columnStoreData = mergeArrays(
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        calendarInstance._columnStore.data,
        columnSetting ?? []
      );
      // @ts-ignore
      calendarInstance.columns.data = columnStoreData;
    });

    setIsSettingsDirty(false);
    onSave();
  };

  return (
    <div className="Settings">
      <ul className="Settings__list">
        <li className="Settings__item odin-pb-0.5 odin-border-b">
          <Checkbox
            label={t('show_all')}
            name="show-all"
            initialChecked={showAll}
            onChange={handleShowAll}
          />
          <span className="odin-text-sm">{t('pin')}</span>
        </li>
        {columnSetting?.map((col) => (
          <li key={col.id} className="Settings__item">
            <Checkbox
              label={col.text}
              name={col.id}
              initialChecked={!col.hidden}
              onChange={handleShowColumn}
            />
            <IconButton
              onClick={() => handlePinColumn(col.id, col.region)}
              aria-label={t('toggle_pin')}
            >
              <OdinIcon
                icon="Pushpin"
                type={
                  col.region === 'normal'
                    ? OdinIconType.Line
                    : OdinIconType.Fill
                }
              />
            </IconButton>
          </li>
        ))}
      </ul>
      <div className="odin-m-1">
        <Button
          onClick={handleSaveSetting}
          title={t('apply_columns_setting')}
          aria-label={t('apply_columns_setting')}
          type="primary"
          classNames={`Filters__action Settings__action flex ${
            !isSettingsDirty ? 'Settings__action--disabled' : ''
          }`}
        >
          <OdinIcon
            icon="Save"
            type={OdinIconType.Line}
            size={OdinIconSize.ExtraSmall}
          />{' '}
          {t('apply_columns_setting')}
        </Button>
      </div>
    </div>
  );
};

export default Settings;
