import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider, UserProvider } from '@myosh/myosh-login';

import UsernameGuard from './shared/guards/username.component';
import RoutesComponent from './shared/routes/routes.component';

const AppWrapper = () => {
  return (
    <Router>
      <AuthProvider>
        <UserProvider>
          <UsernameGuard>
            <RoutesComponent />
          </UsernameGuard>
        </UserProvider>
      </AuthProvider>
    </Router>
  );
};

export default AppWrapper;
