import {
  IconButton,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
} from '@myosh/odin-components';

import './FilterShowMore.scss';

interface FilterShowMoreProps {
  shouldLimitView: boolean;
  isExpanded: boolean;
  onExpandFilter: () => void;
  showAllText: string;
  showLessText: string;
}

const FilterShowMore = ({
  shouldLimitView,
  isExpanded,
  onExpandFilter,
  showAllText,
  showLessText,
}: FilterShowMoreProps) => {
  return shouldLimitView ? (
    <IconButton
      classNames="FilterShowMore"
      onClick={onExpandFilter}
      aria-label={!isExpanded ? showAllText : showLessText}
    >
      <OdinIcon
        icon={!isExpanded ? 'Add' : 'Subtract'}
        type={OdinIconType.Line}
        size={OdinIconSize.ExtraSmall}
      />
      {!isExpanded ? showAllText : showLessText}
    </IconButton>
  ) : null;
};

export default FilterShowMore;
