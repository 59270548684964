import { useState } from 'react';
import { AuthService, useUser } from '@myosh/myosh-login';
import {
  AboutInfoModal,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
} from '@myosh/odin-components';
import { APP_VERSION } from 'Helpers/constants';

const authService = new AuthService();

const AboutComponent = () => {
  const [showAboutInfo, setShowAboutInfo] = useState<boolean>();
  const { state: userState } = useUser();

  const handleLogout = () => {
    localStorage.clear();
    authService.logout();
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowAboutInfo(true)}
        aria-label="View about information"
      >
        <OdinIcon
          icon="Information"
          type={OdinIconType.Line}
          size={OdinIconSize.Small}
        />
      </button>
      <AboutInfoModal
        build={APP_VERSION}
        currentSchema={{ text: userState?.user?.currentSchema ?? 'n/a' }}
        isAboutInfoOpen={showAboutInfo}
        showLogoutButton
        onClose={() => setShowAboutInfo(false)}
        onLogout={handleLogout}
      />
    </>
  );
};

export default AboutComponent;
