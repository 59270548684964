import { IconButton, OdinIcon } from '@myosh/odin-components';

import './PanelToggle.scss';

type PanelTogglePropTypes = {
  isToggled: boolean;
  onToggle: () => void;
};

const PanelToggle = ({ isToggled, onToggle }: PanelTogglePropTypes) => {
  return (
    <IconButton
      onClick={onToggle}
      classNames="PanelToggle"
      aria-label={`${isToggled ? 'Show' : 'Hide'} filter panel`}
    >
      <OdinIcon icon={isToggled ? 'ArrowRightS' : 'ArrowLeftS'} />
    </IconButton>
  );
};

export default PanelToggle;
